import { withStyles, createTheme } from '@material-ui/core/styles';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import EventIcon from '@material-ui/icons/Event';
import NotificationsIcon from '@material-ui/icons/Notifications';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ReplayIcon from '@material-ui/icons/Replay';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import {
    DrcBackdrop,
    DrcButton,
    DrcDialog,
    DrcEnvironmentMarker,
    DrcLoading,
    DrcMain,
    DrcPageNotFound,
    DrcPanel,
    DrcSecureHeader,
    DrcThemeProvider,
    Helmet,
    DrcMediaQueries,
    DrcImage,
    DrcTranslations
} from 'driscolls-react-components';
import { DuThemeUtilities } from 'driscolls-react-utilities';
import { Middleware } from 'one-ring';
import { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { addInfo, hideErrorDialogAction, hideLoadingScreenAction, setErrorsAction, showLoadingScreenAction } from './dota/actions/actions';
import { setInitializeRedirectUrl, setMasterDataInitialized } from './dota/actions/MasterActions';
import LoggingUtilities from './dota/data/LoggingUtilities';
import MasterDataUtilities from './dota/data/MasterDataUtilities';
import LocationMain from './dota/pages/Bypass/LocationMain';
import QuartileMain from './dota/pages/Bypass/QuartileMain';
import Dashboard from './dota/pages/Dashboard';
import Home from './dota/pages/Home';
import InitializeApplication from './dota/pages/InitializeApplication';
import LogOut from './dota/pages/LogOut';
import DistrictGroupMain from './dota/pages/DistrictGroup/DistrictGroupMain';
import NotificationMain from './dota/pages/Notification/NotificationMain';
import ManagerMaintenanceMain from './dota/pages/Maintenance/ManagerMaintenanceMain';
import OverrideMainPage from './dota/pages/Override/OverrideMainPage';
import PointMatrixCreate from './dota/pages/PointMatrix/PointMatrixCreate';
import PointMatrixPreview from './dota/pages/PointMatrix/PointMatrixPreview';
import DispositionOptionsMain from './dota/pages/Disposition/DispositionOptionsMain';
import GrowerPreferencesMain from './dota/pages/Disposition/GrowerPreferencesMain';
import RejectionThresholdMain from './dota/pages/RejectionThreshold/RejectionThresholdMain';
import ProducingAreaMain from './dota/pages/ProducingAreaRegion/ProducingAreaMain';
import SummaryStatisticsMain from './dota/pages/SummaryStatistics/SummaryStatisticsMain';

import ApplicationLayout from './demea/layouts/ApplicationLayout';
import DispositionMainPage from './dota/pages/Disposition/DispositionMainPage';
import './i18n';
import GroupBaseProtect from './routes/GroupBaseProtect';
import UserManagement from './demea/pages/user/UserManagement';

import { routes, routesName } from './demea/routes/routes';
import SiteRoutes from './demea/pages/Site/SiteRoutes';

import { AuthProvider, useAuthService, LoginCallback } from '@driscollsinc/login-module-ui';
import { getAdminGroups, getAllGroups, getManagerGroupsWithAdmin, getAnalystGroupsWithAdmin, getDemeaGroupsWithAdmin } from './utils/getConfigData';

const Releases = lazy(() => import('./dota/pages/Releases'));
const PointMatrixRoutes = lazy(() => import('./demea/pages/PointMatrix/PointMatrixRoutes'));

const adminGroups = getAdminGroups();
const managerGroups = getManagerGroupsWithAdmin();
const analystGroup = getAnalystGroupsWithAdmin();
const demeaGroups = getDemeaGroupsWithAdmin();

const superAdminGroups = adminGroups.filter((g) => g.toLowerCase().includes('super'));

const allLinks = [
    //{ title: 'Dashboard', url: '/Dashboard/', icon: <DashboardIcon /> },
    {
        title: 'Bypass',
        url: '/Bypass/Location/',
        icon: <ShuffleIcon />,
        subLinks: [
            { title: 'Location', url: '/Bypass/Location/' },
            { title: 'Quartile', url: '/Bypass/Quartile/' }
        ]
    },
    { title: 'Override', url: '/Override/', icon: <ReplayIcon /> },
    { title: 'Notifications', url: '/Notification/', icon: <NotificationsIcon /> },
    {
        title: 'Point Matrix',
        url: '/PointMatrix/Create/',
        icon: <SettingsIcon />,
        subLinks: [
            { title: 'Create', url: '/PointMatrix/Create/' },
            { title: 'Preview', url: '/PointMatrix/Preview/' }
        ]
    },
    {
        title: 'QA Manager',
        url: '/Maintenance/',
        icon: <AssignmentIndIcon />
    },
    {
        title: 'District Group',
        url: '/DistrictGroup/',
        icon: <GroupAddIcon />
    },

    {
        title: 'Rejection Threshold',
        url: '/RejectionThreshold/',
        icon: <GroupAddIcon />,
        requiresGroups: managerGroups
    },
    // { title: 'Disposition', url: '/Disposition/', icon: <SettingsIcon />, requiresGroups: adminGroups },
    {
        title: 'Disposition',
        url: '/Disposition/DispositionOptions/',
        icon: <SettingsIcon />,
        subLinks: [
            { title: 'Disposition Options', url: '/Disposition/DispositionOptions/' },
            { title: 'Grower Preferences', url: '/Disposition/GrowerPreferences/' }
        ],
        requiresGroups: adminGroups
    },
    {
        title: 'Producing Area Region',
        url: '/ProducingAreaRegion/',
        icon: <GroupAddIcon />,
        requiresGroups: managerGroups
    },
    {
        title: 'Summary Statistics',
        url: '/SummaryStatistics/',
        icon: <AssignmentIndIcon />,
        requiresGroups: managerGroups
    },
    {
        title: 'Releases',
        url: '/Releases/',
        icon: <EventIcon />,
        requiresGroups: superAdminGroups
    },
    { title: 'Sign Out', url: '/LogOut/', icon: <DirectionsRun /> }
];

let styles = (theme) => ({
    '@global': {
        /* width */
        '::-webkit-scrollbar': {
            width: '10px',
            height: '10px'
        },
        /* Track */
        '::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px grey',
            borderRadius: '5px'
        },
        /* Handle */
        '::-webkit-scrollbar-thumb': {
            backgroundColor: '#6f5091',
            borderRadius: '5px'
        }
    },
    grid: {
        '& .gridCount': {
            margin: '-5px 10px 0 0'
        }
    },
    logo: {
        height: theme.spacing(5),
        marginRight: theme.spacing(3),
        ['@media ' + DrcMediaQueries.mobileL]: {
            display: 'none'
        },
        '@media (prefers-color-scheme: dark)': {
            filter: 'none'
        }
    },
    header: {
        '& .toolbar': {
            minHeight: theme.spacing(8),
            height: theme.spacing(8),
            maxHeight: theme.spacing(8),
            backgroundColor: '#FFF',
            textShadow: `none`
        },
        '& .title': {
            textShadow: `none`,
            fontSize: 22
        },
        '& .appBar': {
            boxShadow: `none`,
            fontSize: theme.spacing(2.5)
        },
        '& .logOut': {
            right: 0,
            position: `absolute`,
            top: theme.spacing(1.5),
            paddingRight: theme.spacing(6),
            '& a:hover': {
                backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), 80)
            }
        },
        '& .logOutLink': {
            color: DuThemeUtilities.DefaultColors.primary.green,
            border: 'none'
        },
        '& .logOutTitle': {
            paddingLeft: theme.spacing(1),
            fontSize: theme.spacing(2)
        }
    }
});

const CreateSimpleTheme = (lightPrimary, darkPrimary) => {
    return createTheme(DuThemeUtilities.CreateSimpleThemeConfig(lightPrimary, darkPrimary));
};

const themes = CreateSimpleTheme(DuThemeUtilities.DefaultColors.primary.purple);

const allGroups = getAllGroups();

const SITE_NAME = 'Quality Maintenance Platform';
const SITE_ACRONYM = 'QMP - ';
const LANDING_PAGE = '/Bypass/Location/';

const setLandingPage = () => {
    if (window.location.pathname.length > 1) {
        return window.location.pathname;
    }

    const redirectURL = sessionStorage.getItem('REDIRECT_URL');

    if (redirectURL?.length > 1) {
        return redirectURL;
    }

    return LANDING_PAGE;
};

const authService = {
    issuer: window.config.OKTA_ISSUER,
    clientId: window.config.OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/implicit/callback'
};

const App = (props) => {
    props.setInitializeRedirectUrl(setLandingPage());
    MasterDataUtilities.Register(props.setInitializeRedirectUrl, props.setMasterDataInitialized);
    LoggingUtilities.Register(props.addInfo);
    Middleware.SetProps(props.showLoadingScreenAction, props.hideLoadingScreenAction, props.setErrorsAction, false);
    const { auth, restoreOriginalUri } = useAuthService(authService);

    const closeErrorDialog = () => {
        props.hideErrorDialog();
    };

    return (
        <DrcThemeProvider theme={themes}>
            <BrowserRouter history={BrowserRouter.browserHistory}>
                <DrcEnvironmentMarker />
                <AuthProvider auth={auth} restoreOriginalUri={restoreOriginalUri}>
                    <Suspense
                        fallback={
                            <DrcMain transparent>
                                <DrcPanel>
                                    <DrcLoading />
                                </DrcPanel>
                            </DrcMain>
                        }
                    >
                        {!window.location.pathname.includes('demea') && (
                            <DrcSecureHeader
                                title={(props.pageTitle ?? '').length > 0 ? SITE_ACRONYM + props.pageTitle : SITE_NAME}
                                allLinks={allLinks}
                                fullWidth={true}
                                logo={
                                    <DrcImage
                                        src={require('./dota/images/Logo_Small_Transparent.png')}
                                        webp={require('./dota/images/Logo_Small_Transparent.webp')}
                                        className={props.classes.logo}
                                        alt="Driscoll's Logo"
                                    />
                                }
                                className={props.classes.header}
                            />
                        )}

                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route
                                path="/demea"
                                element={
                                    <GroupBaseProtect groupsAllowed={demeaGroups} businessUnit="demea">
                                        <ApplicationLayout />
                                    </GroupBaseProtect>
                                }
                            >
                                <Route path={routes[routesName.USER_MANAGEMENT].path} element={<UserManagement />} />
                                <Route path={`${routes[routesName.SITES].path}/*`} element={<SiteRoutes />} />
                                <Route path={`${routes[routesName.POINT_MATRIX].path}/*`} element={<PointMatrixRoutes />} />
                            </Route>
                            <Route path="/implicit/callback" element={<LoginCallback />} />
                            <Route path="/InitializeApplication/" exact element={<InitializeApplication />} />
                            <Route
                                path="/Disposition/"
                                element={
                                    <GroupBaseProtect groupsAllowed={adminGroups}>
                                        <DispositionMainPage />
                                    </GroupBaseProtect>
                                }
                            />
                            <Route
                                path="/Disposition/DispositionOptions/"
                                element={
                                    <GroupBaseProtect groupsAllowed={managerGroups}>
                                        <DispositionOptionsMain />
                                    </GroupBaseProtect>
                                }
                            />
                            <Route
                                path="/Disposition/GrowerPreferences/"
                                element={
                                    <GroupBaseProtect groupsAllowed={managerGroups}>
                                        <GrowerPreferencesMain />
                                    </GroupBaseProtect>
                                }
                            />
                            <Route
                                path="/Dashboard/"
                                element={
                                    <GroupBaseProtect groupsAllowed={allGroups}>
                                        <Dashboard />
                                    </GroupBaseProtect>
                                }
                            />
                            <Route
                                path="/Bypass/Location/"
                                element={
                                    <GroupBaseProtect groupsAllowed={managerGroups}>
                                        <LocationMain />
                                    </GroupBaseProtect>
                                }
                            />
                            <Route
                                path="/Bypass/Quartile/"
                                element={
                                    <GroupBaseProtect groupsAllowed={managerGroups}>
                                        <QuartileMain />
                                    </GroupBaseProtect>
                                }
                            />
                            <Route
                                path="/Override/"
                                element={
                                    <GroupBaseProtect groupsAllowed={managerGroups}>
                                        <OverrideMainPage />
                                    </GroupBaseProtect>
                                }
                            />
                            <Route
                                path="/Notification/"
                                element={
                                    <GroupBaseProtect groupsAllowed={managerGroups}>
                                        <NotificationMain />
                                    </GroupBaseProtect>
                                }
                            />
                            <Route
                                path="/DistrictGroup/"
                                element={
                                    <GroupBaseProtect groupsAllowed={managerGroups}>
                                        <DistrictGroupMain />
                                    </GroupBaseProtect>
                                }
                            />
                            <Route
                                path="/RejectionThreshold/"
                                element={
                                    <GroupBaseProtect groupsAllowed={managerGroups}>
                                        <RejectionThresholdMain />
                                    </GroupBaseProtect>
                                }
                            />
                            <Route
                                path="/ProducingAreaRegion/"
                                element={
                                    <GroupBaseProtect groupsAllowed={managerGroups}>
                                        <ProducingAreaMain />
                                    </GroupBaseProtect>
                                }
                            />
                            <Route
                                path="/SummaryStatistics/"
                                element={
                                    <GroupBaseProtect groupsAllowed={managerGroups}>
                                        <SummaryStatisticsMain />
                                    </GroupBaseProtect>
                                }
                            />
                            <Route
                                path="/PointMatrix/Create/"
                                element={
                                    <GroupBaseProtect groupsAllowed={analystGroup}>
                                        <PointMatrixCreate />
                                    </GroupBaseProtect>
                                }
                            />
                            <Route
                                path="/PointMatrix/Preview/"
                                element={
                                    <GroupBaseProtect groupsAllowed={analystGroup}>
                                        <PointMatrixPreview />
                                    </GroupBaseProtect>
                                }
                            />
                            <Route
                                path="/Maintenance/"
                                element={
                                    <GroupBaseProtect groupsAllowed={superAdminGroups}>
                                        <ManagerMaintenanceMain />
                                    </GroupBaseProtect>
                                }
                            />
                            <Route
                                path="/Releases/"
                                element={
                                    <GroupBaseProtect groupsAllowed={superAdminGroups}>
                                        <Releases />
                                    </GroupBaseProtect>
                                }
                            />
                            <Route path="/LogOut/" exact element={<LogOut />} />
                            <Route element={<DrcPageNotFound />} />
                        </Routes>

                        <div
                            style={{
                                position: 'fixed',
                                bottom: '10px',
                                right: '10px'
                            }}
                        >
                            v. {process.env.REACT_APP_VERSION}
                        </div>
                        <DrcBackdrop isLoading show={props.showLoadingScreen} loadingMessage={props.loadingMessage} />
                        <DrcDialog
                            isError
                            title={props.errorDialog.title}
                            open={props.errorDialog.show}
                            buttons={
                                <DrcButton poly line isError onClick={closeErrorDialog}>
                                    OK
                                </DrcButton>
                            }
                        >
                            {props.errorDialog.content}
                        </DrcDialog>
                    </Suspense>
                </AuthProvider>
            </BrowserRouter>
        </DrcThemeProvider>
    );
};

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingMessage: state.rootReducer.loadingMessage,
        errorDialog: state.rootReducer.errorDialog,
        pageTitle: state.rootReducer.pageTitle
    };
}

const mapDispatchToProps = (dispatch) => ({
    hideErrorDialog: () => dispatch(hideErrorDialogAction()),
    showLoadingScreenAction: (title) => dispatch(showLoadingScreenAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    addInfo: (info) => dispatch(addInfo(info)),
    setMasterDataInitialized: (isInitialized) => dispatch(setMasterDataInitialized(isInitialized)),
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl)),
    setErrorsAction: (title, errors) => dispatch(setErrorsAction(title, errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));
